import React from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '@/styles/globalStyles';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { ComparisonHeader } from '@/features/new-landing-page/sections/comparison-header';
import {
  ComparisonNavChips,
  ComparisonHeaderContent,
  getVisuallyBtn,
  ComparisonPageContent,
  ComparisonReviewsContent,
  ComparisonJoinButtons,
  ComparisonJoinSectionContent,
} from '@/features/new-landing-page/pages/comparison-intelligems-content';
import { Comparison } from '@/features/new-landing-page/sections/comparison';
import { ComparisonJoinSection } from '@/features/new-landing-page/sections/comparison-join';
import { ComparisonReviewsSection } from '@/features/new-landing-page/sections/comparison-reviews';
import { ComparisonNav } from '@/features/new-landing-page/sections/comparison-nav';
import { GradientGlowContainer } from '@/features/new-landing-page/components/gradient-glow-container';
import { ScrollableChips } from '@/features/new-landing-page/components/scrollable-chips';

export function VisuallyIntelligems() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <StyledTopSection>
        <GradientGlowContainer color="#E8EDF7" />
        <ComparisonNav navChips={ComparisonNavChips} />
        <ScrollableWrapper className="only-mobile">
          <ScrollableChips
            items={ComparisonNavChips.mobile}
            styles={ComparisonNavChips.styles}
          />
        </ScrollableWrapper>
        <ComparisonHeader
          headerContent={ComparisonHeaderContent}
          getVisuallyBtn={getVisuallyBtn}
        />
      </StyledTopSection>

      <Comparison pageContent={ComparisonPageContent} />
      <Wrapper className="only-desktop">
        <div style={{ pointerEvents: `none` }} />
        <ComparisonReviewsSection
          title={ComparisonReviewsContent.title}
          subHeader={ComparisonReviewsContent.subHeader}
        />
      </Wrapper>
      <ComparisonJoinSection
        ComparisonButtons={ComparisonJoinButtons}
        ComparisonJoinSectionContent={ComparisonJoinSectionContent}
      />
      <Footer />
    </PageWrapper>
  );
}

const ScrollableWrapper = styled.div`
  display: flex;
  background: transparent;
`;

const StyledTopSection = styled.div`
  background-color: white;
`;

const Wrapper = styled.div`
  width: 100%;
`;
